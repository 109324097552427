import React from 'react'

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
  // Check if it has any has string in url.
	
  if (location.hash !== '') {
	  console.log("hash detected:"+location.hash)
	  
	  if(location.hash == "#positions")
		  {
	   window.setTimeout(() => {
		   let positions = document.querySelector("#positions"); 
		  positions.scrollIntoView();
	   
	   }, 355)
		  }
    return false
  }
	
	
  const transitionDelay = 350
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
  /*
    if (location.action === 'PUSH') {
        window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
    }  else {
        const savedPosition = getSavedScrollPosition(location)
        window.setTimeout(
          () => window.scrollTo(...(savedPosition || [0, 0])),
          transitionDelay
        )
      } */
    return false
}

export const  onClientEntry = () => {
  console.log("We've started!")
 
}

export const wrapPageElement = ({ element }) => {
  return (
    <>
        {element}
    </>
  )
}
